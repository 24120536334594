<template>
    <div>
        <div class="container-fluid">
            <div class="row pb-2">
                <div class="col-10 float-left">
                    <h3 class="d-inline">#訪問看護計画書</h3>
                    <h6 class="d-inline pl-2" v-if="person">
                        {{ person.relationships.facility.attributes.name }} / {{ person.attributes.name }}様
                    </h6>
                </div>
                <div class="col-2 float-right">
                    <button type="button"
                            class="btn btn-outline-dark float-right"
                            @click="openCreateModal">＋
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <table class="table table-bordered bg-white">
                        <thead class="bg-dark">
                        <th>作成者</th>
                        <th>作成日</th>
                        <th>最終更新者</th>
                        <th>最終更新日</th>
                        <th>表示</th>
                        <th>編集</th>
                        </thead>
                        <tbody>
                        <tr v-for="planReport in planReports">
                            <td>{{ planReport.attributes.creator_name }}</td>
                            <td>{{ planReport.attributes.regist_date | moment('YYYY年MM月DD日') }}</td>
                            <td>{{ planReport.attributes.editor_name }}</td>
                            <td>{{ planReport.attributes.updated_at | moment('YYYY年MM月DD日') }}</td>
                            <td><a href="javascript:void(0)" @click="next(planReport)">表示</a></td>
                            <td><a href="javascript:void(0)" @click="openEditModal(planReport)">編集</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <create-home-nursing-plan-modal ref="createModal"
                                        @plan-report-has-created="createPlanReport">
        </create-home-nursing-plan-modal>
        <edit-home-nursing-plan-modal ref="editModal" @plan-report-has-updated="updatePlanReport">
        </edit-home-nursing-plan-modal>
    </div>
</template>

<script>
import CreateHomeNursingPlanModal from "@/components/HomeNursingPlan/CreateHomeNursingPlanModal/CreateHomeNursingPlanModal";
import EditHomeNursingPlanModal from "@/components/HomeNursingPlan/EditHomeNursingPlanModal/EditHomeNursingPlanModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "HomeNursingPlan",
    components: {EditHomeNursingPlanModal, CreateHomeNursingPlanModal},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isSelectTarget: false,
            person: null,
            planReports: {},
        }
    },
    created: function () {
        this.loadPerson();
        this.loadPlanReports();
    },
    methods: {
        openCreateModal: function () {
            this.$refs['createModal'].openModal(this.person);
        },
        openEditModal: function (planReport) {
            this.$refs['editModal'].openModal(this.person, planReport);
        },
        next: function (planReport) {
            this.$router.push({name: 'DisplayHomeNursingPlan', params: {id: planReport.id}});
        },
        loadPerson: function () {
            axios.get(this.routes.person(this.$route.params['id']))
                .then(response => {
                    this.person = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        loadPlanReports: function () {
            axios.get(this.routes.planReports, {
                params: {
                    person_id: this.$route.params['id'],
                }
            })
                .then(response => {
                    this.planReports = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        createPlanReport: function (planReport) {
            this.planReports.unshift(planReport);
        },
        updatePlanReport: function ($event) {
            const index = this.planReports.findIndex(function (planReport) {
                return planReport.id === $event.id;
            });
            if (index !== -1) {
                this.planReports.splice(index, 1, $event);
            }
        }
    }
}
</script>

<style scoped>

</style>
