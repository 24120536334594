<template>
    <CModal
        title="訪問看護計画書 作成"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-xl-12">
                    <form>
                        <div class="form">
                            <v-input label="利用者氏名"
                                     id="create-name"
                                     type="text"
                                     disabled
                                     :value="name">
                            </v-input>
                            <v-input label="生年月日"
                                     id="create-birthday"
                                     type="text"
                                     disabled
                                     :value="birthday">
                            </v-input>
                            <v-input label="年齢"
                                     id="create-age"
                                     type="text"
                                     disabled
                                     unit="歳"
                                     :value="getAge(birthday).toString()">
                            </v-input>
                            <v-input label="要介護・障害認定の状況"
                                     id="create-status"
                                     type="text"
                                     disabled
                                     :value="remark">
                            </v-input>
                            <v-input label="住所"
                                     id="create-address"
                                     type="text"
                                     disabled
                                     :value="address">
                            </v-input>
                            <v-input label="計画書日付"
                                     id="create-regist-date"
                                     type="date"
                                     :errors="hasErrors('regist_date')"
                                     v-model="form.regist_date">
                            </v-input>
                            <v-text-area id="create-long-term-aim"
                                         label="施設内長期目標"
                                         placeholder="入力してください"
                                         :errors="hasErrors('long_term_aim')"
                                         v-model="form.long_term_aim">
                            </v-text-area>
                            <v-text-area id="create-short-term-aim"
                                         label="短期目標"
                                         placeholder="入力してください"
                                         :errors="hasErrors('short_term_aim')"
                                         v-model="form.short_term_aim">
                            </v-text-area>
                            <v-text-area id="create-instruction1"
                                         label="Dr指示内容1"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction1')"
                                         v-model="form.instruction1">
                            </v-text-area>
                            <v-text-area id="create-instruction2"
                                         label="Dr指示内容2"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction2')"
                                         v-model="form.instruction2">
                            </v-text-area>
                            <v-text-area id="create-instruction3"
                                         label="Dr指示内容3"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction3')"
                                         v-model="form.instruction3">
                            </v-text-area>
                            <v-text-area id="create-instruction4"
                                         label="Dr指示内容4"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction4')"
                                         v-model="form.instruction4">
                            </v-text-area>
                            <v-radio-group label="Dr指示内容5選択内容"
                                           :errors="hasErrors('instruction5_choice')">
                                <v-radio label="週1回"
                                         name="radio"
                                         value="0"
                                         id="create-instruction5-choice-0"
                                         :checked="form.instruction5_choice === '0'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="週2回"
                                         name="radio"
                                         value="1"
                                         id="create-instruction5-choice-1"
                                         :checked="form.instruction5_choice === '1'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="週3回以上"
                                         name="radio"
                                         value="2"
                                         id="create-instruction5-choice-2"
                                         :checked="form.instruction5_choice === '2'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="その他"
                                         name="radio"
                                         value="3"
                                         id="create-instruction5-choice-3"
                                         :checked="form.instruction5_choice === '3'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                            </v-radio-group>
                            <v-input id="create-instruction5"
                                     label="Dr指示内容5"
                                     placeholder="入力してください"
                                     :errors="hasErrors('instruction5')"
                                     v-model="form.instruction5"
                                     :disabled="form.instruction5_choice !== '3'">
                            </v-input>
                            <div v-for="(detail, index) in form.details">
                                <div class="form-group row">
                                    <label class="col-2 col-form-label">{{ index === 0 ? '問題点' : '' }}</label>
                                    <div class="col-1">
                                        <button v-if="index === 0"
                                                type="button"
                                                class="btn btn-outline-dark"
                                                @click="addDetailForm">＋
                                        </button>
                                    </div>
                                    <div class="col-7">
                                        <input type="date" class="form-control" placeholder="年月日"
                                               v-model="detail.date">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-3 col-form-label"></label>
                                    <div class="col-7">
                                        <input type="text" class="form-control" placeholder="問題点・解決策"
                                               v-model="detail.description">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-3 col-form-label"></label>
                                    <div class="col-7">
                                        <input type="text" class="form-control" placeholder="評価"
                                               v-model="detail.evaluation">
                                    </div>
                                </div>
                            </div>
                            <v-text-area id="create-home-nursing-target"
                                         label="訪問看護の目標"
                                         placeholder="入力してください"
                                         :errors="hasErrors('care_aim')"
                                         v-model="form.care_aim">
                            </v-text-area>
                            <v-radio-group label="衛生材料が必要な処置の有無" :errors="hasErrors('required_medical_goods')">
                                <v-radio label="有"
                                         name="radio"
                                         value="1"
                                         id="create-required_medical_goods-1"
                                         :checked="form.required_medical_goods === '1'"
                                         :errors="hasErrors('required_medical_goods')"
                                         @change="changeRequiredMedicalGoods">
                                </v-radio>
                                <v-radio label="無"
                                         name="radio"
                                         value="0"
                                         id="create-required_medical_goods-0"
                                         :checked="form.required_medical_goods === '0'"
                                         :errors="hasErrors('required_medical_goods')"
                                         @change="changeRequiredMedicalGoods">
                                </v-radio>
                            </v-radio-group>
                            <v-text-area id="create-treatment"
                                         label="処置の内容"
                                         placeholder="入力してください"
                                         :errors="hasErrors('treatment')"
                                         v-model="form.treatment">
                            </v-text-area>
                            <v-text-area id="create-medical-goods"
                                         label="衛生材料"
                                         placeholder="入力してください"
                                         :errors="hasErrors('medical_goods')"
                                         v-model="form.medical_goods">
                            </v-text-area>
                            <v-text-area id="create-required-amount"
                                         label="必要量"
                                         placeholder="入力してください"
                                         :errors="hasErrors('required_amount')"
                                         v-model="form.required_amount">
                            </v-text-area>
                            <v-text-area id="create-remark"
                                         label="備考"
                                         placeholder="入力してください"
                                         :errors="hasErrors('remark')"
                                         v-model="form.remark">
                            </v-text-area>
                            <v-input id="create-hospital-name"
                                     label="医療機関名"
                                     placeholder="入力してください"
                                     :errors="hasErrors('clinic_name')"
                                     v-model="form.clinic_name">
                            </v-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createPlanReport" :disabled="sending">作成</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VTextArea from "@/components/Forms/VTextArea";
import VRadioGroup from "@/components/Forms/VRadioGroup";
import VRadio from "@/components/Forms/VRadio";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "CreateHomeNursingPlanModal",
    components: {VTextArea, VInput, VRadioGroup, VRadio},
    mixins: [ApiRouter, ErrorHandler, Helpers],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            name: '',
            birthday: '',
            address: '',
            remark: '',
            form: {
                person_id: '',
                regist_date: '',
                long_term_aim: '',
                short_term_aim: '',
                instruction1: '',
                instruction2: '',
                instruction3: '',
                instruction4: '',
                instruction5_choice: '',
                instruction5: '',
                care_aim: '',
                required_medical_goods: '',
                treatment: '',
                medical_goods: '',
                required_amount: '',
                remark: '',
                clinic_name: '',
                details: [
                    {
                        date: '',
                        description: '',
                        evaluation: ''
                    }
                ]
            },
        }
    },
    methods: {
        openModal: function (person) {
            this.isOpened = true;
            this.clearErrors();
            this.initModal(person);
            this.loadInstructionReport();
        },
        initModal: function (person) {
            this.problemCount = 0;
            this.form.person_id = person.id;
            this.name = person.attributes.name;
            this.birthday = person.attributes.birthday;
            this.address = person.attributes.address;
            this.remark = person.attributes.remark;
            this.form.regist_date = '';
            this.form.long_term_aim = '';
            this.form.short_term_aim = '';
            this.form.instruction1 = '';
            this.form.instruction2 = '';
            this.form.instruction3 = '';
            this.form.instruction4 = '';
            this.form.instruction5_choice = '';
            this.form.instruction5 = '';
            this.form.care_aim = '';
            this.form.required_medical_goods = '';
            this.form.treatment = '';
            this.form.medical_goods = '';
            this.form.required_amount = '';
            this.form.remark = '';
            this.form.clinic_name = '';
            this.form.details = [
                {
                    date: '',
                    description: '',
                    evaluation: ''
                }
            ];

        },
        loadInstructionReport: function () {
            axios.get(this.routes.InstructionReports, {
                params: {
                    person_id: this.$route.params['id']
                }
            })
                .then(response => {
                    if (response.data.data.length > 0) {
                        const report = response.data.data[0];
                        this.form.instruction1 = report.attributes.instruction1 ? report.attributes.instruction1 : '';
                        this.form.instruction2 = report.attributes.instruction2 ? report.attributes.instruction2 : '';
                        this.form.instruction3 = report.attributes.instruction3 ? report.attributes.instruction3 : '';
                        this.form.instruction4 = report.attributes.instruction4 ? report.attributes.instruction4 : '';
                        this.form.instruction5_choice = report.attributes.instruction5_choice ?
                            report.attributes.instruction5_choice.toString() : '';
                        this.form.instruction5 = report.attributes.instruction5 ? report.attributes.instruction5 : '';
                    }
                })
                .catch(error => {
                    this.showErrorPopup('Dr指示書がありません');
                });
        },
        changeInstruction5Choice: function ($event) {
            this.form.instruction5_choice = $event;
            if (this.form.instruction5_choice !== '3') {
                this.form.instruction5 = '';
            }
        },
        changeRequiredMedicalGoods: function ($event) {
            this.form.required_medical_goods = $event;
        },
        addDetailForm: function () {
            const additionalForm = {
                date: '',
                description: '',
                evaluation: ''
            }

            if (this.form.details.length < 5) {
                this.form.details.push(additionalForm);
            }
        },
        createPlanReport: function () {
            this.sending = true;
            this.showIndicator('作成中');
            this.rank = 0;

            let formData = new FormData();

            let config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            formData.append('person_id', this.form.person_id);
            formData.append('regist_date', this.form.regist_date);
            formData.append('long_term_aim', this.form.long_term_aim);
            formData.append('short_term_aim', this.form.short_term_aim);
            formData.append('instruction1', this.form.instruction1);
            formData.append('instruction2', this.form.instruction2);
            formData.append('instruction3', this.form.instruction3);
            formData.append('instruction4', this.form.instruction4);
            formData.append('instruction5_choice', this.form.instruction5_choice);
            formData.append('instruction5', this.form.instruction5);
            formData.append('care_aim', this.form.care_aim);
            formData.append('required_medical_goods', this.form.required_medical_goods);
            formData.append('treatment', this.form.treatment);
            formData.append('medical_goods', this.form.medical_goods);
            formData.append('required_amount', this.form.required_amount);
            formData.append('remark', this.form.remark);
            formData.append('clinic_name', this.form.clinic_name);

            this.form.details.forEach((detail, index) => {
                formData.append('details[' + index + '][date]', detail.date);
                formData.append('details[' + index + '][description]', detail.description);
                formData.append('details[' + index + '][evaluation]', detail.evaluation);
                formData.append('details[' + index + '][rank]', (index + 1).toString());
            });

            axios.post(this.routes.planReports, formData, config)
                .then(response => {
                    this.$emit('plan-report-has-created', response.data.data);
                    this.showSuccessPopup('登録しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
